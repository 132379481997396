import React from 'react';
import classNames from 'classnames';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { Badge, SurfaceProps, Text } from '@quality24/design-system';

import Card from '../../atoms/Card';

import * as styles from './PostCard.module.scss';

export interface Props extends SurfaceProps {
  title: string;
  image: IGatsbyImageData;
  tags?: { name: string }[];
  excerpt: string;
}

const PostCard: React.FunctionComponent<Props> = ({
  className,
  title,
  image,
  tags,
  excerpt,
  ...props
}) => {
  return (
    <Card
      className={classNames(styles.wrapper, className)}
      borderRadius="md"
      role="button"
      {...props}
    >
      <div className="row g-2 flex-items-stretch w-100 h-100">
        <div className="col-12 col-md-6">
          <GatsbyImage className={styles.image} image={image} alt={title} />
        </div>
        <div className="col-12 col-md-6 d-flex flex-column flex-items-start gap-2">
          <Text size="lg1" weight="bold">
            {title}
          </Text>
          <Text className="flex-grow-1" size="sm2">
            {excerpt}
          </Text>

          {tags?.map((t) => (
            <Badge key={t.name} className={styles.badge}>
              <Text as="span" size="sm1" weight="bold" color="gray100">
                {t.name}
              </Text>
            </Badge>
          ))}
        </div>
      </div>
    </Card>
  );
};

export default PostCard;
