import '@quality24/design-system/dist/index.css';

import {
  Alert,
  DataTablePagination,
  Heading,
  PillsFilter,
  PillsFilterProps,
  SearchBar,
  Text,
} from '@quality24/design-system';
import classNames from 'classnames';
import { graphql, HeadFC, navigate } from 'gatsby';
import React from 'react';

import useOnScreen from '../utils/useOnScreen';

import MainTemplate from '../components/templates/MainTemplate';
import SEO from '../components/containers/SEO';
import PostCard from '../components/molecules/PostCard';
import ContactLinkCard from '../components/organisms/ContactLinkCard';
import BlogListHero from '../components/organisms/BlogListHero';
import BlogNavbar from '../components/organisms/BlogNavbar';

import SquareGraphic from '../assets/graphic-1.inline.svg';
import * as styles from './blog.module.scss';

const POST_PER_PAGE = 4;

interface QueryResult {
  allContentfulPost: {
    totalCount: number;
    nodes: ShortBlogPost[];
  };
  latestPosts: {
    nodes: ShortBlogPost[];
  };
}

export interface Props {
  data: QueryResult;
}

/**
 * <BlogListing>
 */
const BlogListing: React.FunctionComponent<Props> = ({ data }) => {
  const [page, setPage] = React.useState(0);
  const [category, setCategory] = React.useState('');
  const [search, setSearch] = React.useState('');
  const [pageCount, setPageCount] = React.useState(
    Math.ceil(data.allContentfulPost.totalCount / POST_PER_PAGE),
  );

  const elementRef = React.useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(elementRef);

  // The filtered posts to show
  const posts = React.useMemo(() => {
    const filteredPosts = data.allContentfulPost.nodes.filter((p) => {
      if (category !== '' && p.category.name !== category) return false;
      if (
        search !== '' &&
        !p.title.toLowerCase().includes(search.toLowerCase())
      )
        return false;
      return true;
    });
    const lastPostIndex = Math.min(
      data.allContentfulPost.totalCount,
      (page + 1) * POST_PER_PAGE,
    );

    // Update page count
    setPageCount(Math.ceil(filteredPosts.length / POST_PER_PAGE));
    return filteredPosts.slice(page * POST_PER_PAGE, lastPostIndex);
  }, [data.allContentfulPost, page, category, search]);

  // All unique post categories
  const categories = React.useMemo(() => {
    const catList = new Set<PillsFilterProps['options'][0]>();
    data.allContentfulPost.nodes.forEach((post) =>
      catList.add({ id: post.category.name, label: post.category.name }),
    );
    return [{ id: '', label: 'Todos' }, ...catList];
  }, [data.allContentfulPost]);

  const handleClick = React.useCallback(
    (slug: string) => navigate(`/blog/${slug}`),
    [],
  );

  return (
    <MainTemplate
      navbarElement={BlogNavbar}
      heroContent={
        <BlogListHero posts={data.latestPosts.nodes} onClick={handleClick} />
      }
    >
      <div
        ref={elementRef}
        className={classNames(styles.wrapper, 'container anim anim-slide-up', {
          visible: isVisible,
        })}
      >
        <Heading
          as="h2"
          className="mb-2"
          size="lg1"
          weight="bold"
          color="secondary"
        >
          Todas as publicações
        </Heading>

        <div className="row g-3 mb-3">
          <div className="col-12 col-md-7 col-lg-9">
            <PillsFilter
              name="formCategoryList"
              value={category}
              options={categories}
              onChange={(_name, value) => {
                setPage(0);
                setCategory(value);
              }}
            />
          </div>
          <div className="col-12 col-md-5 col-lg-3 d-flex flex-justify-end">
            <SearchBar
              className="w-100"
              name="formSearch"
              value={search}
              size="sm"
              onChange={(e) => {
                setPage(0);
                setSearch(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="row g-3">
          {posts.length === 0 && (
            <div className="col-12 d-flex flex-justify-center flex-items-center h-10 anim anim-fade-in visible">
              <Alert className="w-100" variant="info">
                <Text size="sm2" color="gray100">
                  Nenhuma postagem encontrada
                </Text>
              </Alert>
            </div>
          )}

          {posts.map((post) => (
            <div
              key={post.slug}
              className="col-12 col-lg-6 anim anim-fade-in visible"
            >
              <PostCard
                title={post.title}
                image={post.heroImage.gatsbyImageData}
                tags={[post.category]}
                excerpt={post.excerpt}
                onClick={() => handleClick(post.slug)}
              />
            </div>
          ))}
        </div>

        <div className="d-flex flex-justify-center my-3">
          <DataTablePagination
            currentPage={page}
            pageSize={POST_PER_PAGE}
            pageCount={pageCount}
            gotoPage={(p) => setPage(p)}
            canNextPage={page < pageCount - 1}
            nextPage={() => setPage((p) => p + 1)}
            canPreviousPage={page > 0}
            previousPage={() => setPage((p) => p - 1)}
          />
        </div>

        <div className="d-flex flex-justify-center mt-5 px-3">
          <ContactLinkCard
            className="w-100"
            onClick={() => navigate('/#contact-us')}
          />
        </div>
      </div>

      <SquareGraphic className={styles.graphic} />
    </MainTemplate>
  );
};

export default BlogListing;

export const pageQuery = graphql`
  query BlogPostListing {
    allContentfulPost(sort: { order: DESC, fields: [createdAt] }, skip: 2) {
      totalCount
      nodes {
        ...ShortBlogPost
      }
    }

    latestPosts: allContentfulPost(
      sort: { order: DESC, fields: [createdAt] }
      limit: 2
    ) {
      nodes {
        ...ShortBlogPost
      }
    }
  }

  fragment ShortBlogPost on ContentfulPost {
    slug
    title
    excerpt
    createdAt

    metadata {
      tags {
        name
      }
    }
    category {
      name
    }

    heroImage {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280)
      file {
        url
      }
    }

    body {
      childMarkdownRemark {
        html
        timeToRead
      }
    }
  }
`;

export const Head: HeadFC = () => {
  return (
    <SEO
      title="Blog Quality24 | Conteúdo para Gestão de Experiência dos Pacientes"
      description="Fique por dentro das principais novidades e tendências sobre a Experiência dos Pacientes. Conteúdo exclusivo. Leia e compartilhe!"
      // image={post.heroImage.file.url}
    />
  );
};
