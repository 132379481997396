import React from 'react';

interface HookOptions {
  threshold?: number;
  once?: boolean;
}

export type UseOnScreenHook = (
  ref: React.RefObject<HTMLElement>,
  options?: HookOptions,
) => boolean;

const defaultOptions: HookOptions = {
  threshold: 0.3,
  once: true,
};

/**
 * Checks if element is visible on screen
 * Usage:
 *
 * @example
 * const elementRef = React.useRef<HTMLDivElement>(null);
 * const isVisible = useOnScreen(elementRef);
 *
 * return <div ref={elementRef}>{isVisible}</div>
 * @returns
 */
const useOnScreen: UseOnScreenHook = (
  ref: React.RefObject<HTMLElement>,
  options,
): boolean => {
  const observerRef = React.useRef<IntersectionObserver | null>(null);
  const [isOnScreen, setIsOnScreen] = React.useState(false);

  const opts = React.useMemo(
    () => ({ ...defaultOptions, ...options }),
    [options],
  );

  // Create observer from IntersectionObserver API
  React.useEffect(() => {
    observerRef.current = new IntersectionObserver(
      ([entry]) => {
        return setIsOnScreen(
          (prev) => (opts.once && prev) || entry.isIntersecting,
        );
      },
      { threshold: opts.threshold },
    );
  }, [opts]);

  React.useEffect(() => {
    if (ref.current && observerRef.current) {
      observerRef.current?.observe(ref.current);
    }
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [ref]);

  return isOnScreen;
};

export default useOnScreen;
