import { Heading } from '@quality24/design-system';
import React from 'react';
import classNames from 'classnames';

import useOnScreen from '../../../utils/useOnScreen';
import PostCard from '../../molecules/PostCard';

import SquareGraphic from '../../../assets/graphic-1.inline.svg';
import BarsGraphic from '../../../assets/graphic-3.inline.svg';
import * as styles from './BlogListHero.module.scss';

export interface Props {
  posts: ShortBlogPost[];
  onClick: (slug: string) => void;
}

const BlogListHero: React.FunctionComponent<Props> = ({ posts, onClick }) => {
  const elementRef = React.useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(elementRef);

  return (
    <div
      ref={elementRef}
      className={classNames(
        styles.wrapper,
        'container pb-4 d-flex flex-column anim anim-slide-up',
        { visible: isVisible },
      )}
    >
      <Heading as="h1" className="mb-3" size="lg1" weight="bold" color="white">
        Últimas publicações
      </Heading>

      <div className="row g-3">
        {posts.map((post) => (
          <div key={post.slug} className="col-12 col-lg-6">
            <PostCard
              title={post.title}
              image={post.heroImage.gatsbyImageData}
              tags={[post.category]}
              excerpt={post.excerpt}
              onClick={() => onClick(post.slug)}
            />
          </div>
        ))}
      </div>

      <SquareGraphic className={classNames(styles.graphic, styles.square)} />
      <BarsGraphic className={classNames(styles.graphic, styles.bars)} />
    </div>
  );
};

export default BlogListHero;
